import React from 'react';
import { styles } from '../org-serviceline/org-servicelines.styles';
import {
  PopoverBody,
  Stack,
  Box,
  Flex,
  Text,
  PopoverContent,
  Portal
} from '@chakra-ui/react';

type DashBoardSelectProps = {
  dashboardSelectionOpened: boolean;
  activeDashboard: string;
  handleDashboardSelection: (boardName: string, dashboardPath: string) => void;
  resources: CMxAPI.Resource[];
};

export const DashboardSelection: React.FC<DashBoardSelectProps> = ({
  dashboardSelectionOpened,
  activeDashboard,
  resources,
  handleDashboardSelection,
  ...props
}) => {
  const classes = styles();

  const tableauDashboardResourceId = resources.find(
    resource => resource.resourceName === 'tableau_dashboard'
  )?.resourceId;
  const sections = resources.filter(
    resource => resource.parentId === tableauDashboardResourceId
  );
  let dashboardOptions: {
    [resourceName: string]: {
      name: string;
      dashboards: { dashboardName: string; dashboardResource: string }[];
    };
  } = {};
  if (sections) {
    sections.forEach(section => {
      const dashboardResources = resources.filter(
        resource => resource.parentId === section.resourceId
      );
      dashboardOptions[section.resourceName] = {
        name: JSON.parse(section.metadata).label,
        dashboards: dashboardResources.map(resource => ({
          dashboardName: resource.label,
          dashboardResource: resource.resourceName
        }))
      };
    });
  }

  return (
    <Portal>
      <Flex className="dashboardSelection">
        <PopoverContent sx={classes['.mainGrid']} className="popOverStyle">
          <PopoverBody sx={classes['.bodyGrid']}>
            <Stack direction="row">
              <Box sx={classes['.popoverInsightsBox']}>
                {dashboardSelectionOpened &&
                  resources.map(resource => {
                    if (dashboardOptions[resource.resourceName]) {
                      const board = dashboardOptions[resource.resourceName];
                      const { name, dashboards } = board;

                      return (
                        <Flex
                          sx={classes['.popoverHeader']}
                          key={name + 'header'}
                          flexDirection="column">
                          {dashboards.map(dashboard => {
                            return (
                              <Flex
                                sx={classes['.switchItem']}
                                key={dashboard.dashboardName}
                                onClick={() => {
                                  handleDashboardSelection(
                                    dashboard.dashboardName,
                                    dashboard.dashboardResource
                                  );
                                }}
                                className={`hoverCursor ${
                                  activeDashboard ===
                                  dashboard.dashboardName
                                    .split(' ')[0]
                                    .toLowerCase()
                                    ? 'selectedItem'
                                    : ''
                                }`}>
                                <Text>{dashboard.dashboardName}</Text>
                              </Flex>
                            );
                          })}
                        </Flex>
                      );
                    } else {
                      return null;
                    }
                  })}
              </Box>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Flex>
    </Portal>
  );
};
