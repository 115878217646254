/// <reference path="../../types.d.ts" />

import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { buildDispatchable } from '../../actions/_action-utilities';

import { DashboardSelection } from './dashboard-selection';
import {
  setActiveDashboard,
  setActiveDashboardResource
} from '../../actions/analytics';

const mapStateToProps = (state: CMx.ApplicationState) => {
  const { analytics, ui } = state;
  return {
    activeDashboard: analytics.activeDashboard,
    resources: ui.context.activeContext?.resources ?? []
  };
};

const mapDispatchToProps = (dispatch: any) => {
  const runDispatchable = buildDispatchable(dispatch);

  return {
    handleDashboardSelection: (
      boardName: string,
      dashboardResource: string
    ) => {
      runDispatchable(() => {
        dispatch(setActiveDashboard(boardName.split(' ')[0].toLowerCase()));
        dispatch(setActiveDashboardResource(dashboardResource));
        return dispatch(push(`/cmx/tableau/`));
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardSelection);
